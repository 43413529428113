import React from 'react';
import { Route, Switch } from 'react-router-dom';

/* PAGES */
/* *************************************************************** */

import Homepage from '../pages/homepage';
import Newsfeed from '../pages/newsfeed/newsfeed';
import FeProjects from '../pages/project/frontend/fe-projects';
import FeProject from '../pages/project/frontend/fe-project';
import FeStory from '../pages/stories/fe-story';
import About from '../pages/about';
import Functions from '../pages/functions/functions';
import LivePost from '../pages/livePost';
import Updates from '../pages/updates';
import Partner from '../pages/partner';
import ForumOverview from '../pages/forum/forumOverview';
import ForumDetail from '../pages/forum/forumDetail';
//import ForumIndex from './pages/forum/forumIndex';
import ForumNew from '../pages/forum/forumNew';
import Activation from '../pages/activation';
import EmailChange from '../pages/emailchange';
import brandIndex from '../pages/project/frontend/brandIndex';
import brandDetail from '../pages/project/frontend/brandDetail';
import Notfound from '../pages/404';
import veteroApp from '../pages/functions/veteroApp';
import SpecialistsOverview from '../pages/professionals/professionalsOverview';
import SpecialistsDetail from '../pages/professionals/professionalsDetail'
import storyInfo from '../pages/functions/storyInfo';
import Postlist from '../pages/blog/postlist';
import SinglePost from '../pages/blog/singlepost';
import Imprint from '../pages/imprint';
import Privacy from '../pages/privacy';
import Conditions from '../pages/conditions';
import Recovery from '../pages/recovery';
import User from '../pages/user'
import UserLiveposts from '../pages/userLiveposts';

//React lazy loading...
// const storyInfo = React.lazy(() => import('../pages/functions/storyInfo'));
// const Postlist = React.lazy(() => import('../pages/blog/postlist'));
// const SinglePost = React.lazy(() => import('../pages/blog/singlepost'));
// const Imprint = React.lazy(() => import('../pages/imprint'));
// const Privacy = React.lazy(() => import('../pages/privacy'));
// const Conditions = React.lazy(() => import('../pages/conditions'));
// const Recovery = React.lazy(() => import('../pages/recovery'));
// const User = React.lazy(() => import('../pages/user'));

const FrontendRoutes = () => {
    return (
        <Switch>
            <Route exact path="/oldtimer/:slug/:id/tab/:tab" component={FeProject}/>
            <Route exact path="/oldtimer/:slug/:storyslug/:storyid" component={FeStory}/>
            <Route exact path="/oldtimer/:slug/:id" component={FeProject}/>
            <Route exact path="/oldtimer" component={FeProjects}/>
            <Route exact path="/forum" component={ForumOverview}/>
            <Route exact path="/forum/page/:page" component={ForumOverview}/>
            <Route exact path="/forum/:slug/:id/page/:page" component={ForumDetail}/>
            <Route exact path="/forum/:slug/:id" component={ForumDetail}/>
            <Route exact path="/forum/new" component={ForumNew}/>
            <Route exact path="/about" component={About}/>
            <Route exact path="/brand" component={brandIndex}/>
            <Route exact path="/brand/:slug" component={brandDetail}/>
            <Route exact path="/professionals" component={SpecialistsOverview} />
            <Route exact path="/professionals/:slug/:id" component={SpecialistsDetail} />
            <Route exact path="/professionals/:slug/:id/:preview" component={SpecialistsDetail} /> 
            <Route exact path="/functions/storys" component={storyInfo}/>
            <Route exact path="/functions/vetero-app" component={veteroApp}/>
            <Route exact path="/functions" component={Functions}/>
            <Route exact path="/oldtimer-blog" component={Postlist}/>
            <Route exact path="/oldtimer-blog/page/:page" component={Postlist}/>
            <Route exact path="/oldtimer-blog/tag/:tag/:tagId" component={Postlist}/>
            <Route exact path="/oldtimer-blog/:slug/:postId" component={SinglePost}/>
            <Route exact path="/user/:slug/:id" component={User}/>
            <Route exact path="/user/:slug/:id/feeds" component={UserLiveposts}/>
            <Route exact path="/feed/post/:id" component={LivePost} />
            <Route exact path="/password_recovery/:user/:code" component={Recovery}/>
            <Route exact path="/activation/:user/:code" component={Activation}/>
            <Route exact path="/email_change/:user/:code" component={EmailChange}/>
            <Route exact path="/impressum" component={Imprint}/>
            <Route exact path="/partner" component={Partner}/>
            <Route exact path="/conditions" component={Conditions}/>
            <Route exact path="/newsfeed" component={Newsfeed}/>
            <Route path="/privacy" component={Privacy}/>
            <Route path="/updates" component={Updates}/>
            <Route exact path="/" component={Homepage}/>
            <Route component={Notfound} />
        </Switch>
    )
}

export default FrontendRoutes
