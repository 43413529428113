import React, { Component } from 'react';
import {
    Header,
    Menu,
    Label,
    Responsive,
    Placeholder,
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom';
import CustomSlidingPane from '../CustomSlidingPane/CustomSlidingPane';
import { connect } from 'react-redux';
import { logoutAccount } from '../../actions/account'
import './SidebarMenu.scss';
import DashboardComments from '../../pages/dashboard/DashboardComments';
import Voucher from '../../pages/dashboard/Voucher';
import OwnProjects from '../../pages/dashboard/OwnProjects';
import Follower from '../../pages/dashboard/follower'
import Likes from '../../pages/dashboard/Likes';
import AccountSettings from '../../pages/dashboard/AccountSettings';
import Forum from '../../pages/dashboard/Forum';
import { setActionAfterLogin } from '../../actions/global';

class SidebarMenu extends Component {

    state = {
        activeItem: null,
        sidebarSlidingPane: {
            isOpen: false,
            content: "",
            title: ""
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { location } = this.props;
        const { activeItem } = this.state;

        //Clear active sidebar item on dashboard
        if(prevState.activeItem && activeItem && location.pathname === '/backend'){
            this.setState({activeItem: null})
        }

    }

    handleActiveItem = (e, { name }) => {
        this.setState({activeItem: name})
    }

    handleSlidingPane = (type, contentTitle) => {
        //Close Sidebar
        this.setState({sidebarSlidingPane: {isOpen: false}})
        this.setState({
            sidebarSlidingPane: {
                isOpen: true,
                content: type,
                title: contentTitle
            }
        })
        this.handleCloseSlidingPane()
    }

    handleCloseSlidingPane = () => {
        const { onClose } = this.props;
        onClose(false);
    }

    handleLogout = () => {
        const { dispatch } = this.props;
        dispatch(logoutAccount());
        dispatch(setActionAfterLogin(null));
    }

    handleSlidingPanesContent = () => {
        const { sidebarSlidingPane } = this.state;
        switch(sidebarSlidingPane.content){
            case "notifications":
                return this.notifications()
            case "account":
                return <AccountSettings />
            case "projects":
                return <OwnProjects close={() => this.handleCloseSlidingPane()}/>
            case "comments":
                return <DashboardComments.DashboardCommentsMobile/>
            case "followers":
                return <Follower.FollowerMobile/>
            case "voucher":
                return <Voucher/>
            case "likes":
                return <Likes.LikesMobile/>
            case "forum":
                return <Forum.ForumMobile/>
            default:
                return null
        }
    }

    render() {

        let sidebarWidth = "85%"
        const { notifications, onClose, item } = this.props
        const { activeItem } = this.state;

        /*if (!notifications || !notifications.count) {
            return (
                <Placeholder>
                    <Placeholder.Line length='full' />
                    <Placeholder.Line length='very long' />
                    <Placeholder.Line length='short' />
                </Placeholder>
            );
        }*/

        return (
            <div className="sidebar-menu">

                {/* Mobile sidebar */}

                <Responsive {...Responsive.onlyMobile}>

                    <Header as="h3">Kommunikation</Header>
                    <Menu.Item onClick={()=>this.handleSlidingPane("comments", "Kommentare")}>
                        <div className="item-wrapper">
                            <span>Kommentare</span>
                            {notifications && notifications.count &&
                                notifications.count.countComments > 0 ?
                                <Label color="red">{notifications.count.countComments}</Label>
                                :
                                null
                            }
                        </div>
                    </Menu.Item>
                    <Menu.Item as={Link} to="/backend/messages" onClick={() => onClose(false)}>
                        <div className="item-wrapper">
                            <span>Nachrichten (PN)</span>
                            {notifications && notifications.count &&
                                notifications.count.countMessages > 0 ?
                                <Label color="red">{notifications.count.countMessages}</Label>
                                :
                                null
                            }
                        </div>
                    </Menu.Item>

                    <Menu.Item onClick={() => this.handleSlidingPane("followers", "Follower")}>
                        <div className="item-wrapper">
                            <span>Follower</span>
                            {notifications && notifications.count &&
                                notifications.count.countFollowers > 0 ?
                                <Label color="red">{notifications.count.countFollowers}</Label>
                                :
                                null
                            }
                        </div>
                    </Menu.Item>
                    <Menu.Item onClick={() => this.handleSlidingPane("likes", "Gefällt mir Angaben")}>
                        <div className="item-wrapper">
                            <span>"Gefällt mir" Angaben</span>
                            {notifications && notifications.count &&
                                notifications.count.countLikes > 0 ?
                                <Label color="red">{notifications.count.countLikes}</Label>
                                :
                                null
                            }
                        </div>
                    </Menu.Item>

                    <Header as="h3">Forum</Header>
                    <Menu.Item onClick={() => this.handleSlidingPane("forum", "Deine Fragen")}>
                        <div className="item-wrapper">
                            <span>Deine Fragen</span>
                        </div>
                    </Menu.Item>

                    <Header as="h3">Spezialisten</Header>
                    <Menu.Item as={Link} to="/backend/professional" onClick={() => onClose(false)}>
                        <div className="item-wrapper">
                            <span>Anzeigen verwalten</span>
                        </div>
                    </Menu.Item> 

                    <Header as="h3">Deine Projekte</Header>
                    <Menu.Item onClick={() => this.handleSlidingPane("projects", "Deine Projekte")}>
                        <div className="item-wrapper">
                            <span>Fahrzeuge</span>
                        </div>
                    </Menu.Item>

                    <Header as="h3">Gutscheine/Rabatte</Header>
                    <Menu.Item onClick={() => this.handleSlidingPane("voucher", "Gutscheine")}>
                        <div className="item-wrapper">
                            <span>Rabatte</span>
                        </div>
                    </Menu.Item>

                    <Header as="h3">Einstellungen</Header>
                    <Menu.Item onClick={()=>this.handleSlidingPane("account", "Profil- / Kontoeinstellungen")}>
                        <div className="item-wrapper">
                            <span>Profil-/Kontoeinstellungen</span>
                        </div>
                    </Menu.Item>
                        {/* <Menu.Item onClick={() => this.handleSlidingPane("notifications", "Benachrichtigungen")}>
                            <div className="item-wrapper">
                                <span>Benachrichtigungen</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item onClick={() => this.handleSlidingPane("notifications", "Benachrichtigungen")}>
                            <div className="item-wrapper">
                                <span>Antworten auf gestellte Fragen</span>
                            </div>
                        </Menu.Item> */}
                        <Menu.Item as={Link} to='/' onClick={() => this.handleLogout()}>
                            <div className="item-wrapper">
                                <span>Ausloggen</span>
                            </div>
                        </Menu.Item>
                        <CustomSlidingPane
                            title={this.state.sidebarSlidingPane.title}
                            isOpen={this.state.sidebarSlidingPane.isOpen}
                            onRequestClose={()=>this.setState({sidebarSlidingPane: {isOpen: false}})}
                        >
                            {this.handleSlidingPanesContent()}
                        </CustomSlidingPane>
                    </Responsive>

                    {/* Tablet sidebar */}

                    <Responsive {...Responsive.onlyTablet}>
                        <Header as="h3">Kommunikation</Header>
                        <Menu.Item
                            as={Link}
                            to="/backend/comments"
                            name="comments"
                            active={activeItem === 'comments'}
                            onClick={() => onClose(false)}

                        >
                            <div className="item-wrapper">
                                <span>Kommentare</span>
                                {notifications && notifications.count &&
                                    notifications.count.countComments > 0 ?
                                    <Label color="red">{notifications.count.countComments}</Label>
                                    :
                                    null
                                }
                            </div>
                        </Menu.Item>
                        <Menu.Item as={Link} to='/backend/messages' name="msg" active={activeItem === 'msg'} onClick={() => onClose(false)} >
                            <div className="item-wrapper">
                                <span>Nachrichten (PN)</span>
                                {notifications && notifications.count &&
                                    notifications.count.countMessages > 0 ?
                                    <Label color="red">{notifications.count.countMessages}</Label>
                                    :
                                    null
                                }
                            </div>
                        </Menu.Item>

                        <Menu.Item as={Link} to="/backend/follower" name="follower" active={activeItem === 'follower'} onClick={() => onClose(false)} >
                            <div className="item-wrapper">
                                <span>Follower</span>
                                {notifications && notifications.count &&
                                    notifications.count.countFollowers > 0 ?
                                    <Label color="red">{notifications.count.countFollowers}</Label>
                                    :
                                    null
                                }
                            </div>
                        </Menu.Item>
                        <Menu.Item as={Link} to="/backend/likes" name="likes" active={activeItem === 'likes'} onClick={() => onClose(false)} >
                            <div className="item-wrapper">
                                <span>"Gefällt mir" Angaben</span>
                                {notifications && notifications.count &&
                                    notifications.count.countLikes > 0 ?
                                    <Label color="red">{notifications.count.countLikes}</Label>
                                    :
                                    null
                                }
                            </div>
                        </Menu.Item>

                        <div className="sidebar-divider"></div>

                        <Header as="h3">Forum</Header>
                        <Menu.Item as={Link} onClick={() => onClose(false)} to="/backend/forum" name="forum" active={activeItem === 'forum'}>
                            <div className="item-wrapper">
                                <span>Deine Fragen</span>
                            </div>
                        </Menu.Item> 

                        <Header as="h3">Spezialisten</Header>
                        <Menu.Item as={Link} to="/backend/professional" onClick={() => onClose(false)}>
                            <div className="item-wrapper">
                                <span>Anzeigen verwalten</span>
                            </div>
                        </Menu.Item>  

                        <div className="sidebar-divider"></div>

                        <Header as="h3">Deine Projekte</Header>
                        <Menu.Item
                            name="projects"
                            as={Link}
                            to="/backend/ownprojects"
                            active={activeItem === 'projects'}
                            onClick={() => onClose(false)}
                        >
                            <div className="item-wrapper">
                                <span>Fahrzeuge</span>
                            </div>
                        </Menu.Item>

                        <Header as="h3">Gutscheine/Rabatte</Header>
                        <Menu.Item
                            as={Link}
                            to="/backend/voucher"
                            name="voucher"
                            active={activeItem === 'voucher'}
                            onClick={() => onClose(false)}
                        >
                            <div className="item-wrapper">
                                <span>Rabatte</span>
                            </div>
                        </Menu.Item>

                        <div className="sidebar-divider"></div>

                        <Header as="h3">Einstellungen</Header>
                        <Menu.Item
                            as={Link}
                            to="/backend/account_settings"
                            name="settings" active={activeItem === 'settings'}
                            onClick={() => onClose(false)}
                        >
                            <div className="item-wrapper">
                                <span>Profil-/Kontoeinstellungen</span>
                            </div>
                        </Menu.Item>
                        {/* <Menu.Item onClick>
                            <div className="item-wrapper" name="notifications" active={activeItem === 'notifications'} onClick={this.handleActiveItem}>
                                <span>Benachrichtigungen</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item onClick>
                            <div className="item-wrapper" name="answers" active={activeItem === 'answers'} onClick={this.handleActiveItem}>
                                <span>Antworten auf gestellte Fragen</span>
                            </div>
                        </Menu.Item> */}
                        <Menu.Item as={Link} to='/' onClick={() => this.handleLogout()}>
                            <div className="item-wrapper">
                                <span>Ausloggen</span>
                            </div>
                        </Menu.Item>
                    </Responsive>

                    {/* Desktop */}

                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                        {/* check if admin is logged in */}
                        {/* {item && item.id === 1 && 
                        <>
                            <Header as="h3">Administrator</Header>
                            <Menu.Item as={Link} to="/backend/admin/professional/overview" name="admin-prof" active={activeItem === 'admin-prof'} onClick={this.handleActiveItem}>
                                <div className="item-wrapper">
                                    <span>Spezialisten verwalten</span>
                                </div>
                            </Menu.Item>
                        </>
                        } */}

                        <Header as="h3">Kommunikation</Header>
                        <Menu.Item as={Link} to="/backend/comments" name="comments" active={activeItem === 'comments'} onClick={this.handleActiveItem}>
                            <div className="item-wrapper">
                                <span>Kommentare</span>
                                {notifications && notifications.count &&
                                    notifications.count.countComments > 0 ?
                                    <Label color="red">{notifications.count.countComments}</Label>
                                    :
                                    null
                                }
                            </div>
                        </Menu.Item>
                        <Menu.Item as={Link} to={notifications && notifications.count && notifications.count.unreadConversationId ? "/backend/messages/"+notifications.count.unreadConversationId : '/backend/messages'} name="msg" active={activeItem === 'msg'} onClick={this.handleActiveItem}>
                            <div className="item-wrapper">
                                <span>Nachrichten (PN)</span>
                                {notifications && notifications.count &&
                                    notifications.count.countMessages > 0 ?
                                    <Label color="red">{notifications.count.countMessages}</Label>
                                    :
                                    null
                                }
                            </div>
                        </Menu.Item>

                        <Menu.Item as={Link} to="/backend/follower" name="follower" active={activeItem === 'follower'} onClick={this.handleActiveItem}>
                            <div className="item-wrapper">
                                <span>Follower</span>
                                {notifications && notifications.count &&
                                    notifications.count.countFollowers > 0 ?
                                    <Label color="red">{notifications.count.countFollowers}</Label>
                                    :
                                    null
                                }
                            </div>
                        </Menu.Item>
                        <Menu.Item as={Link} to="/backend/likes" name="likes" active={activeItem === 'likes'} onClick={this.handleActiveItem}>
                            <div className="item-wrapper">
                                <span>"Gefällt mir" Angaben</span>
                                {notifications && notifications.count &&
                                    notifications.count.countLikes > 0 ?
                                    <Label color="red">{notifications.count.countLikes}</Label>
                                    :
                                    null
                                }
                            </div>
                        </Menu.Item>

                        <div className="sidebar-divider"></div>

                        <Header as="h3">Forum</Header>
                        <Menu.Item as={Link} to="/backend/forum" name="forum" active={activeItem === 'forum'} onClick={this.handleActiveItem}>
                            <div className="item-wrapper">
                                <span>Deine Fragen</span>
                            </div>
                        </Menu.Item> 

                        <Header as="h3">Spezialisten</Header>
                        <Menu.Item as={Link} to="/backend/professional">
                            <div className="item-wrapper">
                                <span>Anzeigen verwalten</span>
                            </div>
                        </Menu.Item>  

                        <div className="sidebar-divider"></div>

                        <Header as="h3">Deine Projekte</Header>
                        <Menu.Item name="projects" as={Link} to="/backend/ownprojects" active={activeItem === 'projects'} onClick={this.handleActiveItem}>
                            <div className="item-wrapper">
                                <span>Fahrzeuge</span>
                            </div>
                        </Menu.Item>

                        <Header as="h3">Gutscheine/Rabatte</Header>
                        <Menu.Item as={Link} to="/backend/voucher" name="voucher" active={activeItem === 'voucher'} onClick={this.handleActiveItem}>
                            <div className="item-wrapper">
                                <span>Rabatte</span>
                            </div>
                        </Menu.Item>

                        <div className="sidebar-divider"></div>

                        <Header as="h3">Einstellungen</Header>
                        <Menu.Item as={Link} to="/backend/account_settings" name="settings" active={activeItem === 'settings'} onClick={this.handleActiveItem}>
                            <div className="item-wrapper">
                                <span>Profil-/Kontoeinstellungen</span>
                            </div>
                        </Menu.Item>
                        {/* <Menu.Item onClick>
                            <div className="item-wrapper" name="notifications" active={activeItem === 'notifications'} onClick={this.handleActiveItem}>
                                <span>Benachrichtigungen</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item onClick>
                            <div className="item-wrapper" name="answers" active={activeItem === 'answers'} onClick={this.handleActiveItem}>
                                <span>Antworten auf gestellte Fragen</span>
                            </div>
                        </Menu.Item> */}
                        <Menu.Item as={Link} to='/' onClick={() => this.handleLogout()}>
                            <div className="item-wrapper">
                                <span>Ausloggen</span>
                            </div>
                        </Menu.Item>
                    </Responsive>


            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { account } = state
    const { notifications, item } = account || {
        notifications: {},
        item: {}
    }

    return {
        notifications,
        item
    }
}


export default connect(mapStateToProps)(withRouter(SidebarMenu))
